import React, { useEffect, useState, useRef } from "react"
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react"
import Button from "react-bootstrap/esm/Button"
import Form from "react-bootstrap/esm/Form"
import InputGroup from "react-bootstrap/esm/InputGroup"
import { Page } from "../components/Page"
import { createSignature } from "../helpers/signMessage"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import bs58 from "bs58"

export default function Profile() {
  const [verificationCode, setVerificationCode] = useState<string>()
  const handleRef = useRef<any>(null)
  const hcaptchaRef = useRef<HCaptcha>(null)
  const [hCaptchaToken, setHCaptchaToken] = useState("")
  const [message, setMessage] = useState("")
  const [ledger, setLedger] = useState(
    typeof localStorage !== "undefined"
      ? localStorage.getItem("useLedger")
      : "false"
  )
  const [registeredHandle, setRegisteredHandle] = useState<string | undefined>(
    undefined
  )
  const { publicKey, signMessage } = useWallet()
  const { connection } = useConnection()
  const wallet = useAnchorWallet()

  const tweetCode = async () => {
    const code = bs58.encode(Buffer.from(handleRef.current.value))
    setVerificationCode(code)
    window.open(
      `http://twitter.com/intent/tweet?text=Solwiki%20verification.%20Code%3A%20${code}`
    )
  }

  const toggleLedger = () => {
    localStorage.setItem("useLedger", ledger === "true" ? "false" : "true")
    setLedger(localStorage?.getItem("useLedger") || "false")
  }

  const verifyAndRegister = async () => {
    if (!verificationCode) return
    const useLedger =
      localStorage.getItem("useLedger") === "true" ? true : false
    let signature

    if (!handleRef.current) {
      throw Error("No input field?")
    }

    try {
      signature = await createSignature(
        connection,
        wallet,
        publicKey,
        signMessage,
        `register|${handleRef.current.value}|${verificationCode}`
      )
    } catch (e: any) {
      // Do nothing
      return
    }

    const res = await fetch(`${process.env.GATSBY_BACKEND_ENDPOINT}register`, {
      method: "POST",
      body: JSON.stringify({
        signature,
        publicKey: (publicKey || "").toString(),
        handle: handleRef.current.value,
        hcaptchaToken: hCaptchaToken,
        ledger: useLedger,
      }),
    })

    hcaptchaRef.current?.resetCaptcha()
    setHCaptchaToken("")

    const result = await res.json()

    if (!result.error) {
      setRegisteredHandle(handleRef.current.value)
    } else {
      setMessage(
        "Could not verify Twitter account. It can take a bit for the Twitter API to return new Tweets, please try again in a minute or so."
      )
    }
  }

  useEffect(() => {
    if (!publicKey) {
      return
    }

    fetch(
      `${process.env.GATSBY_BACKEND_ENDPOINT}getWriter/${publicKey.toString()}`
    )
      .then(res => res.json())
      .then(res => {
        if (res.twitterHandle) {
          setRegisteredHandle(res.twitterHandle)
        } else {
          setRegisteredHandle("")
        }
      })
  }, [publicKey])

  if (!publicKey) {
    return (
      <Page>
        <h3>Profile</h3>
        <p>Please connect first to manage your profile</p>
      </Page>
    )
  }

  return (
    <Page>
      <h3>Profile</h3>

      <p>
        Sign messages using ledger:{" "}
        <strong>{ledger === "true" ? "yes" : "no"}</strong>{" "}
        <button onClick={toggleLedger}>Switch</button>
      </p>

      <p>
        You are connected as: <span>{publicKey.toString()}</span>
      </p>
      {registeredHandle && (
        <>
          <p>
            You have connected this Twitter handle:{" "}
            <span>{registeredHandle}</span>
          </p>
        </>
      )}
      {registeredHandle !== undefined && !registeredHandle && (
        <>
          <h5>Connect your Twitter account</h5>
          <p>
            By connecting your Twitter account, your posts will show up under
            your Twitter handle instead of your addres. In order to verify,
            tweet the verification number using the button below. Then, submit
            the verification by signing a message that will be verified by our
            backend. When approved, your Twitter handle will be attached to your
            connected wallet.
          </p>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
            <Form.Control
              placeholder="Twitter handle"
              aria-label="Twitter handle"
              aria-describedby="basic-addon2"
              ref={handleRef}
            />
          </InputGroup>
          <Button onClick={tweetCode}>Tweet verification code</Button>
          {verificationCode && (
            <>
              <br />
              <br />

              <HCaptcha
                sitekey={process.env.GATSBY_HCAPTCHA_SITE_KEY!}
                onVerify={token => setHCaptchaToken(token)}
                ref={hcaptchaRef}
              />

              <br />
              <Button onClick={verifyAndRegister}>
                Connect Twitter to Solwiki profile
              </Button>
            </>
          )}

          {message && (
            <strong>
              <br />
              <br />
              {message}
            </strong>
          )}
        </>
      )}
    </Page>
  )
}
